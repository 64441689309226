import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';
import MemberBenefitStyles from '../components/styles/ member-benefit-styles';

const MemberBenefits = () => (
    <Layout>
        <SEO title="Member Benefits" />
        <Title>Member Benefits</Title>
        <MemberBenefitStyles>
            <div className="list__container">
                <ul>
                    <li>
                        <p className="number">1</p>
                        <p>
                            Organization and unity among fellow franchisees to
                            advocate your interests to LCE.
                        </p>
                    </li>
                    <li>
                        <p className="number">2</p>
                        <p>
                            Eligible to vote on issues of importance in
                            Association led pulling of franchisees. There is
                            strength in numbers!
                        </p>
                    </li>
                    <li>
                        <p className="number">3</p>
                        <p>
                            Participation in our ‘Weekly Sales Survey’. Member
                            franchisees from around the country share weekly
                            sales and cost results.
                        </p>
                    </li>
                    <li>
                        <p className="number">4</p>
                        <p>
                            Eligible to serve on joint LCE / IOLCF committees.
                            The Marketing Committee reviews all future Caesar
                            Fund expenditures and the Operations Committee is
                            consulted on changes to operational procedures,
                            specifications and the franchise agreement.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <p className="number">5</p>
                        <p>
                            Protection for your future through professional
                            legal counsel.
                        </p>
                    </li>
                    <li>
                        <p className="number">6</p>
                        <p>Eligible to vote in IOLCF elections.</p>
                    </li>
                    <li>
                        <p className="number">7</p>
                        <p>
                            The Toga Times quarterly news magazine. Providing
                            news about franchising and food service that will
                            help you run your business.
                        </p>
                    </li>
                    <li>
                        <p className="number">8</p>
                        <p>
                            General membership meetings to discuss issues
                            affecting your business and network with fellow
                            franchisees.
                        </p>
                    </li>
                    <li>
                        <p className="number">9</p>
                        <p>
                            A web-site that is a resource for you to learn
                            information about your association, the corporation
                            and franchise news.
                        </p>
                    </li>
                    <li>
                        <p className="number">10</p>
                        <p>
                            Vendor discounts through franchisee group
                            purchasing.’
                        </p>
                    </li>
                </ul>
            </div>

            <div className="vendor__container">
                <Title>Vendor Partnership Programs</Title>
                <div className="list-vendor__container">
                    <ul>
                        <li className="li-vendor">
                            <h6>Business Insurance</h6>
                            <a
                                href="https://www.usi.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                USI Insurance Services
                            </a>
                            <p>
                                Providing P&C and WC insurance with reduced
                                premiums and lower risk through large group
                                participation.
                            </p>
                        </li>
                        <li className="li-vendor">
                            <h6>Health Insurance</h6>
                            <a
                                href="https://www.elevantahealth.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Elevanta Health
                            </a>
                            <p>
                                Providing Blue Cross / Blue Shield health
                                insurance to individual operators, their
                                employees and families.
                            </p>
                        </li>
                    </ul>
                    <ul>
                        <li className="li-vendor">
                            <h6>Credit Card Processing</h6>
                            <a
                                href="https://0feesolutions.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Titanium Payments
                            </a>
                            <p>
                                Eliminate credit card processing fees with Cash
                                Discount credit card program
                            </p>
                        </li>
                        <li className="li-vendor">
                            <h6>Payroll</h6>
                            <a
                                href="https://www.tapcheck.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Tapcheck
                            </a>
                            <p>Payroll on demand at no cost to the employer.</p>
                        </li>
                        <li className="li-vendor">
                            <h6>Business Services</h6>
                            <a
                                href="https://www.aboutsib.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                SIB Fixed Cost Reduction
                            </a>
                            <p>
                                Discover if you’re overpaying on waste removal,
                                telecom, and more!
                            </p>

                            <a
                                href="https://www.emcentrix.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Emcentrix
                            </a>
                            <p>
                                All in one electronic HR platform that
                                integrates with your payroll and POS system to
                                eliminate paperwork.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="JoinNow__container">
                <p className="JoinNow__message">
                    Dedicated to building win-win relationships on behalf of all
                    members, large and small.
                </p>
                <a
                    className="JoinNow__button"
                    href="/join-now"
                    style={{ textDecoration: 'none' }}
                >
                    Join Today
                </a>
            </div>
        </MemberBenefitStyles>
    </Layout>
);

export default MemberBenefits;
